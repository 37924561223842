import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Seo } from '../components';
import { Homepage } from '../utils';
import { Blocks } from '../components';
import HomepageHero from '../components/homepageHero/homepageHero';

interface QueryResult {
  strapi: {
    homepage: Homepage | null;
  };
}

export const query = graphql`
  query {
    strapi {
      homepage {
        title
        subtitle
        banner {
          url
        }
        tb_event {
          title
          location
          date
          banner {
            url
          }
        }
        structure {
          __typename
          ... on STRAPI_ComponentContentRichText {
            id
            text
          }
          ... on STRAPI_ComponentContentWhiteLogoBar {
            logos {
              link
              logo {
                url
              }
            }
          }
          ... on STRAPI_ComponentContentDropletSectionList {
            title
            sections {
              title
              subTitle
              paragraph
              image {
                formats
              }
              buttonLabel
              buttonLink
            }
          }
          ... on STRAPI_ComponentContentSpotlight {
            title
            image {
              url
            }
            buttonLabel
            buttonLink
            tb_event {
              title
              location
              date
              banner {
                url
              }
            }
            inhouse_days {
              title
              slug
              startDate
              location
              company {
                logo {
                  url
                }
              }
            }
          }
          ... on STRAPI_ComponentContentCallToAction {
            title
            subTitle
            buttonLabel
            buttonLink
            buttonType
            color
          }
          ... on STRAPI_ComponentContentTimeline {
            title
            steps {
              title
              description
            }
            conclusion
          }
          ... on STRAPI_ComponentContentVideoEmbed {
            title
            embedCode
            thumbnail {
              url
            }
          }
          ... on STRAPI_ComponentContentTestimonialList {
            title
            testimonials {
              study
              quote
              name
              image {
                alternativeText
                id
                url
              }
              id
              excerpt
              age
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }: { data: QueryResult }) => {
  const page = data.strapi.homepage;

  if (!page) {
    return (
      <Layout>
        <Seo title="Home" />
        <section>
          <div className="container">
            <h1>Deze pagina wordt momenteel bijgewerkt</h1>
            <p>
              Navigeer via het menu naar de gewenste pagina of ga direct naar <a href="/event">het event</a>
            </p>
          </div>
        </section>
      </Layout>
    );
  }

  return (
    <Layout>
      <Seo title="Home" />
      <HomepageHero
        title={page.title}
        subtitle={page.subtitle}
        backgroundImage={page.banner?.url}
        currentEvent={page.tb_event}
      />
      <Blocks blocks={page?.structure || []} insetFirst />
    </Layout>
  );
};

export default IndexPage;
