import React, { useMemo } from 'react';
import { gql } from '@apollo/client';
import { navigate } from '@reach/router';
import { useActiveModal, useAuth, useEvent, useMicrocopy } from '../../hooks';
import { useBackendMutation } from '../../hooks/useBackendMutation';
import { nowTillDate } from '../../utils';
import { Button } from 'antd';

const ENROLL = gql`
  mutation enroll($input: EnrollUserInput!) {
    enroll(input: $input) {
      id
    }
  }
`;

const EnrollButton = ({ classNames }: { classNames?: { button?: string, countdown?: string, root?: string } }) => {
  const { tbEvent, openForSignup } = useEvent();
  const { me, refetch } = useAuth();
  const m = useMicrocopy();
  const [enroll] = useBackendMutation(ENROLL, { onCompleted: refetch });
  const enrolled = useMemo(() => Boolean(me?.tbEventEnrollments?.find(e => e.tbEvent.id === tbEvent?.id)), [
    me,
    tbEvent,
  ]);
  const { setActiveModal } = useActiveModal();

  const onEnroll = async () => {
    await enroll({
      variables: {
        input: {
          type: 'TB_EVENT',
          id: tbEvent?.id,
        },
      },
    });
  };

  const daysLeft = useMemo(() => {
    if (!tbEvent) return 0;
    return nowTillDate(tbEvent.finalSignupDate);
  }, [tbEvent]);

  const handleEnrollmentClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onEnroll();
    event.currentTarget.disabled = true;
    setActiveModal('enroll');
  };

  if (!tbEvent) return null;

  if (!enrolled && daysLeft <= 0) return <em>Inschrijving is gesloten</em>;

  return (
    <div className={classNames?.root}>
      <div className={classNames?.button}>
        {!me && (
          <Button type="primary" onClick={() => setActiveModal('login')}>
            Inloggen en aanmelden
          </Button>
        )}

        {me && !enrolled && openForSignup && (
          <Button type="primary" onClick={handleEnrollmentClick}>
            Meld je aan!
          </Button>
        )}

        {me && enrolled && (
          <Button type="primary" disabled style={{ background: '#022e45', color: '#fff', border: 'none' }}>
            Je bent ingeschreven!
          </Button>
        )}

        {me && (
          <Button type="primary" onClick={() => navigate('/student/account')}>
            Naar je profiel
          </Button>
        )}
      </div>

      {!enrolled && (
        <em className={classNames?.countdown}>
          <span>
            Nog maar <strong>{daysLeft}</strong> {daysLeft === 1 ? 'dag' : 'dagen'} om je in te schrijven
          </span>
        </em>
      )}
    </div>
  );
};

export default EnrollButton;
